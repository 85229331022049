define('flexi-layouts/helpers/-inject-layout', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var Helper = _ember['default'].Helper;
  var inject = _ember['default'].inject;

  exports['default'] = Helper.extend({

    layoutService: inject.service('device/layout'),

    compute: function compute() {
      return this.get('layoutService');
    }
  });
});