define('ember-leaflet/initializers/leaflet-assets', ['exports', 'ember-get-config'], function (exports, _emberGetConfig) {
  'use strict';

  exports.initialize = initialize;

  /* global L */

  function initialize() /* container, application */{
    if (typeof FastBoot === 'undefined') {
      var prefix = '';

      if (!Ember.isNone(_emberGetConfig['default'].rootURL)) {
        prefix = _emberGetConfig['default'].rootURL;
      } else if (!Ember.isNone(_emberGetConfig['default'].baseURL)) {
        prefix = _emberGetConfig['default'].baseURL;
      }

      L.Icon.Default.imagePath = prefix + 'assets/images/';
    }
  }

  exports['default'] = {
    name: 'leaflet-assets',
    initialize: initialize
  };
});