define('flexi-layouts/mixins/container', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var Mixin = _ember['default'].Mixin;
  var computed = _ember['default'].computed;
  var run = _ember['default'].run;
  var inject = _ember['default'].inject;

  exports['default'] = Mixin.create({
    deviceLayout: inject.service('device/layout'),
    width: 0,
    inserted: false,
    classNameBindings: ['breakpointClass'],
    breakpointClass: computed('inserted', 'width', function () {
      var bps = this.get('deviceLayout.breakpoints');

      if (!this.get('inserted')) {
        return 'container-' + bps[0].prefix;
      }

      var width = this.element.clientWidth;

      for (var i = 0; i < bps.length; i++) {
        if (width >= bps[i].begin) {
          return 'container-' + bps[i].prefix;
        }
      }
      return 'container-breakpoint-unavailable';
    }),

    _elementResize: null,
    elementResize: function elementResize(dims) {
      this.set('width', dims.width);
    },

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super();
      this._elementResize = this.elementResize.bind(this);

      run.schedule('afterRender', function () {
        // Ember before v2.10 can arrive in afterRender with a null element.
        // Details here: https://github.com/html-next/flexi/issues/101
        if (_this.isDestroying) return;

        _this.set('inserted', true);
        _this.get('deviceLayout').monitor.addElementHandler(_this.element, _this._elementResize);
      });
    },

    willDestroyElement: function willDestroyElement() {
      this._super();
      this.set('inserted', false);
      this.get('deviceLayout').monitor.removeElementHandler(this.element, this._elementResize);
      this._elementResize = null;
    }
  });
});