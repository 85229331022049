define('ember-composable-helpers/helpers/without', ['exports', 'ember-composable-helpers/-private/create-needle-haystack-helper', 'ember-composable-helpers/utils/includes'], function (exports, _emberComposableHelpersPrivateCreateNeedleHaystackHelper, _emberComposableHelpersUtilsIncludes) {
  'use strict';

  exports.without = without;

  function contains(needle, haystack) {
    return (0, _emberComposableHelpersUtilsIncludes['default'])(Ember.A(haystack), needle);
  }

  function without(needle, haystack) {
    if (!Ember.isArray(haystack)) {
      return false;
    }

    if (Ember.typeOf(needle) === 'array' && Ember.get(needle, 'length')) {
      return haystack.reduce(function (acc, val) {
        return contains(val, needle) ? acc : acc.concat(val);
      }, []);
    }

    return Ember.A(haystack).without(needle);
  }

  exports['default'] = (0, _emberComposableHelpersPrivateCreateNeedleHaystackHelper['default'])(without);
});