define('ember-leaflet/helpers/div-icon', ['exports'], function (exports) {
  'use strict';

  var isFastBoot = typeof FastBoot !== 'undefined';
  /* global L */

  var divIcon = isFastBoot ? function () {} : function divIcon(_, hash) {
    return L.divIcon(hash);
  };

  exports.divIcon = divIcon;
  exports['default'] = Ember.Helper.helper(divIcon);
});