define('ember-leaflet/index', ['exports', 'ember-leaflet/components/circle-layer', 'ember-leaflet/components/circle-marker-layer', 'ember-leaflet/components/geojson-layer', 'ember-leaflet/components/image-layer', 'ember-leaflet/components/leaflet-map', 'ember-leaflet/components/marker-layer', 'ember-leaflet/components/polygon-layer', 'ember-leaflet/components/polyline-layer', 'ember-leaflet/components/popup-layer', 'ember-leaflet/components/tile-layer', 'ember-leaflet/components/tooltip-layer', 'ember-leaflet/components/wms-tile-layer', 'ember-leaflet/macros/to-lat-lng'], function (exports, _emberLeafletComponentsCircleLayer, _emberLeafletComponentsCircleMarkerLayer, _emberLeafletComponentsGeojsonLayer, _emberLeafletComponentsImageLayer, _emberLeafletComponentsLeafletMap, _emberLeafletComponentsMarkerLayer, _emberLeafletComponentsPolygonLayer, _emberLeafletComponentsPolylineLayer, _emberLeafletComponentsPopupLayer, _emberLeafletComponentsTileLayer, _emberLeafletComponentsTooltipLayer, _emberLeafletComponentsWmsTileLayer, _emberLeafletMacrosToLatLng) {
  'use strict';

  var L = window.L || {};
  exports.L = L;
  exports.Leaflet = L;
  Object.defineProperty(exports, 'CircleLayer', {
    enumerable: true,
    get: function get() {
      return _emberLeafletComponentsCircleLayer['default'];
    }
  });
  Object.defineProperty(exports, 'CircleMarkerLayer', {
    enumerable: true,
    get: function get() {
      return _emberLeafletComponentsCircleMarkerLayer['default'];
    }
  });
  Object.defineProperty(exports, 'GeojsonLayer', {
    enumerable: true,
    get: function get() {
      return _emberLeafletComponentsGeojsonLayer['default'];
    }
  });
  Object.defineProperty(exports, 'ImageLayer', {
    enumerable: true,
    get: function get() {
      return _emberLeafletComponentsImageLayer['default'];
    }
  });
  Object.defineProperty(exports, 'LeafletMap', {
    enumerable: true,
    get: function get() {
      return _emberLeafletComponentsLeafletMap['default'];
    }
  });
  Object.defineProperty(exports, 'MarkerLayer', {
    enumerable: true,
    get: function get() {
      return _emberLeafletComponentsMarkerLayer['default'];
    }
  });
  Object.defineProperty(exports, 'PolygonLayer', {
    enumerable: true,
    get: function get() {
      return _emberLeafletComponentsPolygonLayer['default'];
    }
  });
  Object.defineProperty(exports, 'PolylineLayer', {
    enumerable: true,
    get: function get() {
      return _emberLeafletComponentsPolylineLayer['default'];
    }
  });
  Object.defineProperty(exports, 'PopupLayer', {
    enumerable: true,
    get: function get() {
      return _emberLeafletComponentsPopupLayer['default'];
    }
  });
  Object.defineProperty(exports, 'TileLayer', {
    enumerable: true,
    get: function get() {
      return _emberLeafletComponentsTileLayer['default'];
    }
  });
  Object.defineProperty(exports, 'TooltipLayer', {
    enumerable: true,
    get: function get() {
      return _emberLeafletComponentsTooltipLayer['default'];
    }
  });
  Object.defineProperty(exports, 'WmsTileLayer', {
    enumerable: true,
    get: function get() {
      return _emberLeafletComponentsWmsTileLayer['default'];
    }
  });
  Object.defineProperty(exports, 'toLatLng', {
    enumerable: true,
    get: function get() {
      return _emberLeafletMacrosToLatLng['default'];
    }
  });
});