define('ember-leaflet/components/interactive-layer', ['exports', 'ember-leaflet/components/base-layer'], function (exports, _emberLeafletComponentsBaseLayer) {
  'use strict';

  exports['default'] = _emberLeafletComponentsBaseLayer['default'].extend({

    leafletOptions: Object.freeze(['interactive', 'bubblingMouseEvents']),

    leafletEvents: Object.freeze(['click', 'dblclick', 'mousedown', 'mouseup', 'mouseover', 'mouseout', 'contextmenu'])

  });
});