define('ember-leaflet/helpers/lat-lng-bounds', ['exports'], function (exports) {
  'use strict';

  var isFastBoot = typeof FastBoot !== 'undefined';
  /* global L */

  var latLngBounds = isFastBoot ? function () {} : function (latLngs) {
    return L.latLngBounds(latLngs);
  };

  exports.latLngBounds = latLngBounds;
  exports['default'] = Ember.Helper.helper(latLngBounds);
});