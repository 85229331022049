define("ember-composable-helpers/helpers/array", ["exports"], function (exports) {
  "use strict";

  exports.array = array;

  function array() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

    // slice params to avoid mutating the provided params
    return Ember.A(params.slice());
  }

  exports["default"] = Ember.Helper.helper(array);
});