define('flexi-layouts/lib/monitor', ['exports', 'ember-runloop'], function (exports, _emberRunloop) {
  'use strict';

  var _createClass = (function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];descriptor.enumerable = descriptor.enumerable || false;descriptor.configurable = true;if ('value' in descriptor) descriptor.writable = true;Object.defineProperty(target, descriptor.key, descriptor);
      }
    }return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);if (staticProps) defineProperties(Constructor, staticProps);return Constructor;
    };
  })();

  exports.addResizeHandler = addResizeHandler;
  exports.removeResizeHandler = removeResizeHandler;

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError('Cannot call a class as a function');
    }
  }

  var DEFAULT_ARRAY_SIZE = 10;

  var ResizeMonitor = (function () {
    function ResizeMonitor() {
      _classCallCheck(this, ResizeMonitor);

      this.elements = new Array(DEFAULT_ARRAY_SIZE);
      this.maxLength = DEFAULT_ARRAY_SIZE;
      this.length = 0;
      this.handlers = new Array(DEFAULT_ARRAY_SIZE);
      this.isPolling = false;
    }

    _createClass(ResizeMonitor, [{
      key: 'addElementHandler',
      value: function addElementHandler(element, handler) {
        var index = this.elements.indexOf(element);

        if (index === -1) {
          index = this.length++;

          if (index === this.maxLength) {
            this.maxLength *= 2;
            this.elements.length = this.maxLength;
            this.handlers.length = this.maxLength;
          }

          this.elements[index] = element;
          this.handlers[index] = { width: 0, height: 0, handlers: [handler] };
        } else {
          var handlers = this.handlers[index].handlers;

          handlers.push(handler);
        }

        if (!this.isPolling) {
          this.poll();
        }
      }
    }, {
      key: 'removeElementHandler',
      value: function removeElementHandler(element, handler) {
        var elementIndex = this.elements.indexOf(element);

        if (elementIndex === -1) {
          return;
        }

        var elementCache = this.handlers[elementIndex];

        if (elementCache && elementCache.handlers) {
          var index = elementCache.handlers.indexOf(handler);

          if (index === -1) {
            throw new Error('Attempted to remove an unattached handler');
          }

          elementCache.handlers.splice(index, 1);

          // cleanup element entirely if needed
          if (!elementCache.handlers.length) {
            this.elements.splice(elementIndex, 1);
            this.handlers.splice(elementIndex, 1);
            this.length--;
            this.maxLength--;
          }
        } else {
          throw new Error('Attempted to remove an unattached handler');
        }
      }
    }, {
      key: 'poll',
      value: function poll() {
        var _this = this;

        this.isPolling = true;

        requestAnimationFrame(function () {
          var _loop = function _loop(i) {
            var element = _this.elements[i];
            var info = _this.handlers[i];
            var currentWidth = element.clientWidth;
            var currentHeight = element.clientHeight;
            var widthChanged = currentWidth !== info.width && info.width !== 0;
            var heightChanged = currentHeight !== info.height && info.height !== 0;

            info.width = currentWidth;
            info.height = currentHeight;

            if (widthChanged || heightChanged) {
              _emberRunloop['default'].join(function () {
                for (var j = 0; j < info.handlers.length; j++) {
                  info.handlers[j].call(null, info);
                }
              });
            }
          };

          for (var i = 0; i < _this.length; i++) {
            _loop(i);
          }

          _this.isPolling = _this.length > 0;
          if (_this.isPolling) {
            _this.poll();
          }
        });
      }
    }]);

    return ResizeMonitor;
  })();

  exports.ResizeMonitor = ResizeMonitor;

  var instance = new ResizeMonitor();

  function addResizeHandler(element, handler) {
    instance.addElementHandler(element, handler);
  }

  function removeResizeHandler(element, handler) {
    instance.removeElementHandler(element, handler);
  }

  exports['default'] = instance;
});