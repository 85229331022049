define('ember-leaflet/helpers/icon', ['exports'], function (exports) {
  'use strict';

  var isFastBoot = typeof FastBoot !== 'undefined';
  /* global L */

  var icon = isFastBoot ? function () {} : function icon(_, hash) {
    return L.icon(hash);
  };

  exports.icon = icon;
  exports['default'] = Ember.Helper.helper(icon);
});