define("ember-composable-helpers/helpers/object-at", ["exports"], function (exports) {
  "use strict";

  exports.objectAt = objectAt;
  var _slicedToArray = (function () {
    function sliceIterator(arr, i) {
      var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;_e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }return _arr;
    }return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  })();

  function objectAt(index, array) {
    if (!Ember.isArray(array)) {
      return undefined;
    }

    index = parseInt(index, 10);

    return Ember.A(array).objectAt(index);
  }

  exports["default"] = Ember.Helper.extend({
    content: Ember.computed('index', 'array.[]', function () {
      var index = Ember.get(this, 'index');
      var array = Ember.get(this, 'array');

      return objectAt(index, array);
    }),

    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          index = _ref2[0],
          array = _ref2[1];

      Ember.set(this, 'index', index);
      Ember.set(this, 'array', array);

      return Ember.get(this, 'content');
    },

    contentDidChange: Ember.observer('content', function () {
      this.recompute();
    })
  });
});