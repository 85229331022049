define('flexi-layouts/services/device/layout', ['exports', 'ember-metal/utils', 'ember-computed', 'ember-evented', 'ember-runloop', 'ember-service', 'flexi-layouts/utils/capitalize', 'flexi-layouts/lib/monitor', 'ember'], function (exports, _emberMetalUtils, _emberComputed, _emberEvented, _emberRunloop, _emberService, _flexiLayoutsUtilsCapitalize, _flexiLayoutsLibMonitor, _ember) {
  'use strict';

  var defineProperty = _ember['default'].defineProperty;

  exports['default'] = _emberService['default'].extend(_emberEvented['default'], {
    breakpoints: null,
    height: 500,
    monitor: _flexiLayoutsLibMonitor['default'],
    width: 1000,
    _resizeHandler: null,

    orientation: (0, _emberComputed['default'])('width', 'height', function () {
      var resolution = this.getProperties('width', 'height');
      var isLandscape = resolution.width >= resolution.height;

      return isLandscape ? 'landscape' : 'portrait';
    }).readOnly(),

    orientationIsLandscape: _emberComputed['default'].equal('orientation', 'landscape'),
    orientationIsPortrait: _emberComputed['default'].not('orientationIsLandscape'),

    init: function init() {
      this._super();

      this.setupBreakpoints();

      if (typeof window === 'object' && typeof document === 'object') {
        this.setupResize();
        this.updateResolution();
      }
    },

    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      if (typeof window === 'object' && typeof document === 'object') {
        window.removeEventListener('resize', this._resizeHandler, true);
      }
    },

    setupBreakpoints: function setupBreakpoints() {
      var _this = this;

      if (!this.breakpoints) {
        throw new Error('You must configure some breakpoints');
      }

      // sort breakpoints largest to smallest
      this.breakpoints = this.breakpoints.sort(function (a, b) {
        return a.begin > b.begin ? -1 : 1;
      });

      // sort smallest to largest
      var bps = (0, _emberMetalUtils.copy)(this.breakpoints, true).sort(function (a, b) {
        return a.begin > b.begin ? 1 : -1;
      });

      bps.forEach(function (bp, i) {

        defineProperty(_this, 'is' + (0, _flexiLayoutsUtilsCapitalize['default'])(bp.name), (0, _emberComputed['default'])('width', function () {
          var width = this.get('width');
          var next = bps[i + 1];

          if (next) {
            return width >= bp.begin && width < next.begin;
          }
          return width >= bp.begin;
        }));

        defineProperty(_this, 'isAtLeast' + (0, _flexiLayoutsUtilsCapitalize['default'])(bp.name), (0, _emberComputed['default'])('width', function () {
          var width = this.get('width');

          return width >= bp.begin;
        }));
      });
    },

    setupResize: function setupResize() {
      var _this2 = this;

      this._resizeHandler = function () {
        _emberRunloop['default'].debounce(_this2, _this2.updateResolution, 16);
      };
      window.addEventListener('resize', this._resizeHandler, true);
    },

    /**
     * Runs when resized and fires off events
     */
    updateResolution: function updateResolution() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }
      var oldWidth = this.get('width');
      var oldHeight = this.get('height');
      var width = this._currentWidth();
      var height = this._currentHeight();

      this.setProperties({
        width: width,
        height: height
      });

      if (oldWidth !== width) {
        this.trigger('width-change');
      }

      if (oldHeight !== height) {
        this.trigger('height-change');
      }

      if (oldWidth !== width || oldHeight !== height) {
        this.trigger('resize');
      }
    },

    _currentWidth: function _currentWidth() {
      return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    },

    _currentHeight: function _currentHeight() {
      return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    }
  });
});