define("ember-composable-helpers/helpers/repeat", ["exports"], function (exports) {
  "use strict";

  exports.repeat = repeat;
  var _slicedToArray = (function () {
    function sliceIterator(arr, i) {
      var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;_e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }return _arr;
    }return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  })();

  function repeat(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        length = _ref2[0],
        value = _ref2[1];

    if (Ember.typeOf(length) !== 'number') {
      return [value];
    }
    return Array.apply(null, { length: length }).map(function () {
      return value;
    }); // eslint-disable-line
  }

  exports["default"] = Ember.Helper.helper(repeat);
});