define('ember-composable-helpers/helpers/previous', ['exports', 'ember-composable-helpers/utils/get-index', 'ember-composable-helpers/-private/create-needle-haystack-helper'], function (exports, _emberComposableHelpersUtilsGetIndex, _emberComposableHelpersPrivateCreateNeedleHaystackHelper) {
  'use strict';

  exports.previous = previous;

  function previous(currentValue, array) {
    var useDeepEqual = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

    var currentIndex = (0, _emberComposableHelpersUtilsGetIndex['default'])(array, currentValue, useDeepEqual);

    if (Ember.isEmpty(currentIndex)) {
      return;
    }

    return currentIndex === 0 ? currentValue : Ember.A(array).objectAt(currentIndex - 1);
  }

  exports['default'] = (0, _emberComposableHelpersPrivateCreateNeedleHaystackHelper['default'])(previous);
});